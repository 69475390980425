<template>
  <div class="bg-img"></div>
  <div class="custom-height">
    <div class="d-flex flex-column align-items-center" style="padding-top: 120px">
      <div class="card">
        <div class="card-body">
          <div class="text-center">
            <div class="oops-text">OOPS!</div>
            <div class="heading-07 text-neutral-05 mt-3 mb-5">
              Nu am putut găsi pagina pe care o cauți
            </div>
            <div class="row justify-content-between">
              <div class="col-sm-auto mb-4 mb-sm-0">
                <router-link
                  :to="{ name: 'HomePage' }"
                  class="button btn-medium-wider btn-primary-outlined fw-semibold"
                >
                  <svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.97502 5.44165L2.91669 10.5L7.97502 15.5583"
                      stroke="#D6366C"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M17.0834 10.5H3.05835"
                      stroke="#D6366C"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  Înapoi acasă
                </router-link>
              </div>
              <div class="col-sm-auto">
                <router-link
                  :to="{ name: 'Products' }"
                  class="button btn-medium-wider btn-primary-solid h-100 pe-sm-5 ps-sm-5"
                >
                  Produse <img src="../../assets/images/icons/arrow-right-20x20.svg"  alt="..."/>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'NoPageFound',
  data() {
    return {
      referringRoute: '',
    };
  },
};
</script>
<style scoped>
@import url('https://fonts.cdnfonts.com/css/turkish-venetian-301-bt');
.oops-text {
  font-family: 'Turkish Venetian 301 BT', sans-serif;
  font-size: 80px;
  font-weight: 500;
  line-height: 80px;
  letter-spacing: 0em;
}
.bg-img {
  position: absolute;
  bottom: 101px;
  width: 100%;
  height: 100vh;
  background-image: url('../../assets/images/not-found-page-bg.webp');
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
  z-index: -1;
}

.card-body {
  padding: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card {
  box-shadow: 0px 5px 60px 0px #0000001a;
  border-radius: 24px;
  border: 0px;
  max-width: 515px;
  width: 100%;
  background-color: var(--whitetint-7);
}
@media (min-width: 1200px) {
  .custom-height {
    height: calc(100vh - 64px - 101px);
  }
}
</style>
